import React from 'react';
import NotFound from '../components/organisms/404/NotFound';
import Head from '../components/layout/Head';
import { LayoutBlank } from '../components/layout/Layout';

const FourOhFour = () => {
  return (
    <>
      <Head title="404 - Page not found" />
      <LayoutBlank>
        <NotFound />
      </LayoutBlank>
    </>
  );
};

export default FourOhFour;
